<template>
  <div id="DistributorReport">

    <v-row class="mb-5 ml-0">
      <v-icon @click="$router.go(-1)" class="mr-2">mdi-arrow-left</v-icon>
      Reports <v-icon size="16" class="ml-2">mdi-greater-than</v-icon> <span class="font-weight-bold ml-2">Distributor Report</span>
    </v-row>

    <v-row class="ml-1">
      <v-col cols="auto">
        <date-range v-model="date" auto-apply appendToBody opens="right"> <!-- @update="getSalesReport" -->
          <template v-slot:input="picker" class="d-flex">
            <v-icon class="mr-3">mdi-calendar-blank</v-icon>
            <template>{{ picker.startDate | dateFormat }} - {{ picker.endDate | dateFormat }}</template>
          </template>
        </date-range>
      </v-col>
      <v-col cols="3">
        <v-autocomplete :items="Distributors" item-text="name" clearable outlined hide-details
                        :filter="distributorFilter" item-value="id" v-model="selectedDistributor" label="Search Distributor">
          <template v-slot:item="data">
            <v-list-item-content>
              <v-list-item-title> {{ data.item.name }}</v-list-item-title>
              <v-list-item-subtitle>
                <template v-if="data.item.Users.length">{{data.item.Users[0].name}} | </template>
                {{ data.item.mobile }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-autocomplete :items="[{id:'all', name:'All'}, ...routes]" item-text="name" clearable outlined hide-details class="ac-field"
                        item-value="id" v-model="selectedRoute" multiple chips deletable-chips label="Search Route"/>
      </v-col>
      <v-col cols="3">
        <v-autocomplete :items="[{id:'all', name:'All', Users:[]}, ...customers]" item-text="name" clearable outlined hide-details class="ac-field"
                        :filter="distributorFilter" item-value="id" v-model="selectedCustomer" multiple chips deletable-chips label="Search Customer">
          <template v-slot:item="data">
            <v-list-item-content>
              <v-list-item-title> {{ data.item.name }}</v-list-item-title>
              <v-list-item-subtitle>
                <template v-if="data.item.Users.length">{{data.item.Users[0].name}} | </template>
                {{ data.item.mobile }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="3">
        <v-select :items="salesByOptions" item-text="name" outlined hide-details
                  item-value="id" v-model="selectedSalesBy" label="Sales By"/>
      </v-col>
      <v-col cols="auto">
        <v-checkbox v-model="tempShowGroupData" label="Group By Package Type" hide-details/>
      </v-col>
      <v-col cols="12">
        <v-btn depressed class="mr-4 ml-4 primary" @click="getReport()" :disabled="disableApplyBtn">Apply</v-btn>
        <v-btn outlined @click="onClearFilter">Clear</v-btn>
      </v-col>
    </v-row>

    <v-data-table :headers="headers" :items="reportData" @update:page="$vuetify.goTo(0)"
                  :footer-props="{itemsPerPageOptions:rowsPerPageItems}" :loading="loading" disable-sort>
      <template v-slot:no-data>No Records Available</template>
      <template v-slot:no-results>No Records Available</template>
      <template v-slot:body="{ items }">
        <tbody>
        <template v-for="(item, index) in items">
          <tr :key="index"  @click="toggleGroupRow(item)" :style="item.show?'background: #dedede':''">
            <td v-if="showGroupData">
              <v-icon>mdi-{{ item.show ? 'chevron-up' : 'chevron-down' }}</v-icon> {{item.packageType}}
            </td>
            <td v-else>
              <v-avatar class="my-3 avatar mr-3" title="View Image" v-if="item.hasOwnProperty('productImage')">
                <v-img v-if="item.productImage" :src="imgWpx(item.productImage, 200)" contain alt="image" @error="item.productImage = null">
                  <template v-slot:placeholder>
                    <v-icon size="50" color="blue-grey lighten-5">mdi-image</v-icon>
                    <v-progress-circular width="3" size="24" indeterminate style="position: unset" color="deep-orange lighten-2"></v-progress-circular>
                  </template>
                </v-img>
                <v-icon v-else size="50" color="blue-grey lighten-4">mdi-image</v-icon>
              </v-avatar>
              <div style="display: inline-block">{{item.productName}} <br>
                <v-chip label text-color="white" x-small :style="`background-color: ${packageColor[item.packageType]}`">
                  {{ item.packageType.toUpperCase() }}</v-chip>
              </div>
            </td>
            <td>{{item.productPrice | currencyFilter}}</td>
            <td>{{item.productWeight}} {{showGroupData?'KG':''}}</td>
          </tr>
          <tr v-for="(s, idx) in item.products" :key="index+'-'+idx" v-show="item.show">
            <td style="padding-left: 50px">
              <v-avatar class="my-3 avatar mr-3" title="View Image" v-if="s.hasOwnProperty('productImage')">
                <v-img v-if="s.productImage" :src="imgWpx(s.productImage, 200)" contain alt="image" @error="s.productImage = null">
                  <template v-slot:placeholder>
                    <v-icon size="50" color="blue-grey lighten-5">mdi-image</v-icon>
                    <v-progress-circular width="3" size="24" indeterminate style="position: unset" color="deep-orange lighten-2"></v-progress-circular>
                  </template>
                </v-img>
                <v-icon v-else size="50" color="blue-grey lighten-4">mdi-image</v-icon>
              </v-avatar>
              <div style="display: inline-block">{{s.productName}} <br>
                <v-chip label text-color="white" x-small :style="`background-color: ${packageColor[s.packageType]}`">
                  {{ s.packageType.toUpperCase() }}</v-chip>
              </div>
            </td>
            <td>{{s.productPrice | currencyFilter}}</td>
            <td>{{s.productWeight}}</td>
          </tr>
        </template>
        <tr v-if="!items.length" class="v-data-table__empty-wrapper"><td colspan="3">No Data Available</td></tr>
        </tbody>
      </template>
    </v-data-table>

    <Snackbar :toaster="toaster"/>
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import Constants from "../../Constants/Constants";
import moment from 'moment';
import axios from 'axios';
import Snackbar from "../Common/Snackbar";
import Vue from 'vue';
import {GET_DISTRIBUTORS_FOR_REPORT} from "../../graphql/queries/Organizations";
import {IDS} from "../../Constants/IDS";
import {GET_ORGANIZATION_ASSIGNED_ROUTES, GET_ROUTE_SHOPS_BY_ROUTE_ID} from "../../graphql/queries/Routes";
export default {
  name: "DistributorReport",
  components:{Snackbar, dateRange: DateRangePicker},
  apollo:{
    Distributors: {
      query: GET_DISTRIBUTORS_FOR_REPORT, fetchPolicy: 'network-only',
      variables () {return { orgType: [IDS.organizationTypes.Distributor] }}
    },
    routes: {
      query: GET_ORGANIZATION_ASSIGNED_ROUTES, fetchPolicy: 'network-only',
      skip(){return !this.selectedDistributor},
      variables () {return { orgId: [this.selectedDistributor] }}
    },
    RouteShops: {
      query: GET_ROUTE_SHOPS_BY_ROUTE_ID, fetchPolicy: 'network-only',
      skip(){return !this.selectedRoute},
      variables () {return { routeId: this.selectedRoute.includes('all') ? null : this.selectedRoute }},
      result({data}) { this.customers = data.RouteShops.map(s => s.Organization) }
    }
  },
  data(){
    return{
      Constants, moment,
      showGroupData: false,
      tempShowGroupData: false,
      groupBy: 'packageType',
      tempGroupBy: 'productId',
      reportData: [],
      loading: false,
      headers: [{text: 'PRODUCT', value: 'product'}, {text: 'PRICE', value: 'productPrice'},
        {text: 'WEIGHT', value: 'productWeight'}],
      rowsPerPageItems: [25, 50, 100, -1],
      date: {startDate: moment().subtract(10, 'days'), endDate: moment()},
      toaster: { enable:false },
      packageColor: JSON.parse(localStorage.packageColor),
      Distributors: [], selectedDistributor: '',
      routes: [], selectedRoute: ['all'],
      customers: [], selectedCustomer: ['all'],
      salesByOptions: [{id:1, name:'Pilot Salesman'}, {id:2, name:'Self'}], selectedSalesBy: 1
    }
  },
  computed:{
    disableApplyBtn(){
      // return (!this.selectedCustomer&&!this.selectedProduct);
      return false;
    }
  },
  methods:{
    toggleGroupRow(item){
      if(this.showGroupData) Vue.set(item, 'show', !item.show)
    },
    distributorFilter (item, queryText) {
      queryText = queryText.toLowerCase();
      return item.name?.toLowerCase().indexOf(queryText) > -1 || (item.Users.length&&item.Users[0].name.toLowerCase().indexOf(queryText)>-1);
    },
    onClearFilter(){
      this.selectedDistributor = '';
      this.selectedRoute = ['all'];
      this.selectedCustomer = ['all'];
      this.selectedSalesBy = 1;
      this.reportData = [];
    },
    async getReport(){
      if(!this.selectedDistributor)
        return this.toaster = {enable:true, color:'red', message: 'Please select Distributor'};
      this.loading = true;
      let request = {
        startDate: moment(this.date.startDate).format('YYYY-MM-DD'),
        endDate: moment(this.date.endDate).format('YYYY-MM-DD'),
        distributorId: this.selectedDistributor || '',
        routeId: this.selectedRoute.includes('all') ? null : this.selectedRoute,
        customerId: this.selectedCustomer.includes('all') ? null : this.selectedCustomer,
        salesBy: this.selectedSalesBy
      }
      axios({method:'POST', url: Constants.api_url + 'getDistributorSalesOrder', data:request}).then(response => {
        if(response.data.flag) {
          if(!response.data.data.length) this.toaster = {enable:true, color:'red', message: 'No data available'};
          if(this.tempShowGroupData){
            let packageObj = {};
            response.data.data.forEach(p => {
              if(!packageObj[p.packageType]) packageObj[p.packageType] = {products:[], productPrice:0, productWeight:0};
              packageObj[p.packageType].products.push(p);
              packageObj[p.packageType].productPrice += p.productPrice;
              packageObj[p.packageType].productWeight += Number(p.productWeight.replace('KG', ''));
            })
            this.reportData = Object.keys(packageObj).map(pkg => ({packageType:pkg, ...packageObj[pkg]}))
          }else this.reportData = response.data.data;
          this.loading = false;
          this.showGroupData = this.tempShowGroupData;
        }
      }).catch(error => {
        this.loading = false;
        if (error.message === 'Network Error') {
          this.toaster = {enable:true, color:'red', message: error.message}
        } else this.toaster = {enable:true, color:'red', message: `API Call Fail`}
      })
    },
  }
}
</script>

<style scoped>
#DistributorReport {
  padding: 20px;
}
/deep/ .reportrange-text{
  /*width: 212px;*/
  text-align: center;
  padding: 14px 10px;
  border: 1px solid rgba(0,0,0,.14);
  margin: 0 0 0 0;
}
.avatar{
  height: 74px !important;
  width: 74px !important;
  cursor: pointer;
  border-radius: unset !important;
}
</style>
<style>
.ac-field.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed .v-select__selections{
  min-height: 56px;
}
</style>
